import styled from 'styled-components';

export default styled.h2`
  color: hsl(0, 0%, 100%);
  font-family: 'GT America', 'Helvetica Neue', Helvetica, Arial, Tahoma, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin: 0;
  padding: 0;
  text-transform: lowercase;

  @media screen and (min-width: 700px) {
    & {
      font-size: 48px;
      line-height: 56px;
    }
  }
`;
