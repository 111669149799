import React from 'react';
import PropTypes from 'prop-types';

import Container from './container';
import Card from './card';
import Content from './content';
import MessageSelector from './message-selector';

const SelectMessage = ({
  cardColor,
  chooseTemplate,
  content,
  generateContent,
  messageValid,
  name,
  onSubmit,
  selectedTemplate: Template,
  templates,
  updateContent,
  updateName
}) => (
  <Container>
    <Content>
      <MessageSelector
        chooseTemplate={ chooseTemplate }
        generateContent={ generateContent }
        selectedTemplate={ Template }
        templates={ templates }
      />
    </Content>
    <Card cardColor={ cardColor }>
      <Template
        content={ content }
        messageValid={ messageValid }
        name={ name }
        onSubmit={ onSubmit }
        updateContent={ updateContent }
        updateName={ updateName }
        viewOnly={ false }
      />
    </Card>
  </Container>
);

SelectMessage.propTypes = {
  cardColor: PropTypes.string.isRequired,
  chooseTemplate: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  generateContent: PropTypes.func.isRequired,
  messageValid: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    relationship: PropTypes.string.isRequired
  }),
  templates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    relationship: PropTypes.string.isRequired
  })).isRequired,
  updateContent: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired
}

export default SelectMessage;
