import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PageTitle from '../../components/type/page-title';
import Body from '../../components/type/body';

const Container = styled.div`
`;

const Title = styled(PageTitle)`
  margin: 0 0 40px;
  text-align: center;
`;

const Intro = styled(Body)`
  text-align: center;
  margin: 0 auto;
  max-width: 400px;
`;

const ColorList = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 816px;
  padding: 32px 24px 0;

  @media screen and (min-width: 700px) {
    & {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

const Color = ({ color, name, selected, onClick }) => (
  <div onClick={ onClick }>
    <ColorSwatch color={ color } selected={selected} />
    <ColorTitle>
      { name }
    </ColorTitle>
  </div>
)

Color.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

const ColorSwatch = styled.div`
  background: ${props => props.color};
  border: 2px solid transparent;
  border-color: ${props => props.selected ? 'hsl(0, 0%, 100%)' : 'transparent'};
  border-radius: 50%;
  box-shadow: 2px 2px 6px 0 hsla(0, 0%, 0%, 0.26);
  cursor: pointer;
  height: 10vmin;
  margin: 0 auto 9px;
  max-height: 145px;
  max-width: 145px;
  min-height: 75px;
  min-width: 75px;
  transition: border-color 300ms ease-out;
  width: 10vmin;
`;

const ColorTitle = styled(Body)`
  margin: 0 0 10px;
  padding: 0 20px;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0 0 20px;
  }
`;

const ColorSelector = ({
  chooseColor,
  colors = [],
  selectedColor
}) => (
  <Container>
    <Title>
      Pick a color.
    </Title>
    <Intro>
      Select a color theme to use for your message.
    </Intro>
    <ColorList>
      {
        colors.map(color => (
          <Color
            key={ color.id }
            name={color.name}
            color={color.value}
            onClick={ () => chooseColor(color) }
            selected={color === selectedColor}
          />
        ))
      }
    </ColorList>
  </Container>
);

ColorSelector.propTypes = {
  chooseColor: PropTypes.func.isRequired,
  selectedColor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired
};

export default ColorSelector;
