import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ColorSelector from './color-selector';
import Container from './container';
import Card from './card';
import Content from './content';
import Submit from './submit-button';

const CardContent = styled.div`
  box-sizing: border-box;
  padding: 36px 24px;
`;

const SelectColor = ({
  chooseColor,
  colors,
  onSubmit,
  selectedColor
}) => (
  <Container>
    <Content>
      <ColorSelector
        chooseColor={ chooseColor }
        colors={ colors }
        selectedColor={ selectedColor }
      />
    </Content>
    <Card cardColor={ selectedColor.value }>
      <CardContent>
        <Submit onClick={ onSubmit }>
          Next
        </Submit>
      </CardContent>
    </Card>
  </Container>
);

SelectColor.propTypes = {
  chooseColor: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedColor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
};

export default SelectColor;
