import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import Body from '../../components/type/body';
import Intro from './introduction';
import PageTitle from '../../components/type/page-title';
import { OutlineButton, PlainButton } from '../../components/controls/button';

import arrow from '../../images/icon-arrow.svg';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(PageTitle)`
  margin: 0 0 20px;
  padding: 0 20px;
  text-align: center;

  @media screen and (min-width: 700px) {
    & {
      margin: 0 0 40px;
    }
  }
`;

const RelationshipContainer = styled.div`
  border: 1px solid hsla(0, 0%, 100%, .5);
  border-left-color: ${props => props.allOptionsFit ? 'hsla(0, 0%, 100%, .5)' : 'transparent'};
  border-right-color: ${props => props.allOptionsFit ? 'hsla(0, 0%, 100%, .5)' : 'transparent'};
  border-radius: ${props => props.allOptionsFit ? '3px' : '0'};
  box-sizing: border-box;
  margin: 28px auto;
  max-width: 100%;
  position: relative;

  @media (min-width: 768px) {
    margin: 54px auto 28px;
  }
`;

const RelationshipControl = styled.div`
  position: relative;
  overflow-x: ${props => props.touchenabled ? 'auto' : 'hidden'};
  -webkit-overflow-scrolling: touch;
`;

const Prev = styled.div`
  background-image: url(${ arrow }),
                    linear-gradient(270deg, #323628 20px, rgba(50, 54, 40, 0));
  background-repeat: no-repeat,
                     repeat;
  background-position: 60% 50%,
                       0% 0%;
  cursor: pointer;
  display: ${ props => props.shouldDisplay ? 'block' : 'none'};
  height: calc(100% - 2px);
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(180deg);
  width: 55px;
  z-index: 1;
`;

const Next = styled(Prev)`
  left: auto;
  right: 0;
  transform: rotate(0deg);
`;

const RelationshipList = styled.ul`
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  left: ${({left, touchEnabled}) => touchEnabled ? 0 : left};
  list-style: none;
  margin: 0;
  padding: ${({allOptionsFit, touchEnabled}) => allOptionsFit || touchEnabled ? '12px' : '12px 55px'};
  position: relative;
  transition: left 300ms ease-out;
`;

const RelationshipOption = styled(PlainButton)`
  background-color: ${props => props.selected ? 'hsl(0, 0%, 100%)' : 'transparent'};
  color: ${props => props.selected ? 'hsl(0, 0%, 28%)' : 'hsl(0, 0%, 100%)'};
  padding: 0 16px;
  transition: background-color 300ms ease-out;
  white-space: nowrap;
`;

const MixUp = styled(OutlineButton)`
  color: hsl(0, 0%, 100%);
  display: block;
  margin: 0 auto;
  max-width: 230px;
  width: 100%;
`;

class MessageSelector extends React.Component {
  constructor(props) {
    super(props);

    this.currentCenter = 0;
    this.containerRef = React.createRef();
    this.listRef = React.createRef();
    this.state = {
      listOffset: '25%',
      allOptionsFit: false,
      touchEnabled: false
    };

    this.handleTouch = this.handleTouch.bind(this);
    this.move = this.move.bind(this);
    this.moveTo = this.moveTo.bind(this);
    this.delayedAdjustControls = debounce(this.adjustControls.bind(this), 100);
  }

  componentDidMount() {
    if (typeof window !== undefined) {
      //window.addEventListener("touchstart", this.handleTouch, false);
      window.addEventListener("resize", this.delayedAdjustControls, false);
      setTimeout(this.adjustControls.bind(this), 500);
    }
  }

  componentWillUnount() {
    if (typeof window !== undefined) {
      window.removeEventListener("touchstart", this.handleTouch);
      window.removeEventListener("resize", this.delayedAdjustControls, false);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedTemplate: previousTemplate } = prevProps;
    const { templates, selectedTemplate } = this.props;
    const { allOptionsFit } = this.state;
    const index = templates.indexOf(selectedTemplate);

    if (selectedTemplate === previousTemplate || allOptionsFit) return;

    this.moveTo(index);
  }

  adjustControls() {
    const container = this.containerRef.current;
    const list = this.listRef.current;
    const { templates, selectedTemplate } = this.props;
    const index = templates.indexOf(selectedTemplate);

    if (list.scrollWidth <= container.offsetWidth) {
      this.setState({
        allOptionsFit: true,
        listOffset: '0'
      });
    }
    else {
      this.setState({
        allOptionsFit: false
      }, () => this.moveTo(index));
    }
  }

  handleTouch() {
    this.setState({ touchEnabled: true });

    if (typeof window !== undefined) window.removeEventListener("touchstart", this.handleTouch);
  }

  move(direction) {
    const nextIndex = this.currentCenter + direction;
    const list = this.listRef.current;
    const items = Array.prototype.slice.call(list.children);
    const { chooseTemplate, templates } = this.props;

    if (nextIndex < 0 || nextIndex >= items.length) return;

    const template = templates[nextIndex];

    chooseTemplate(template);
  }

  moveTo(index) {
    const list = this.listRef.current;
    const items = Array.prototype.slice.call(list.children);
    const containerBox = this.containerRef.current.getBoundingClientRect();
    const item = items[index];
    const box = item.getBoundingClientRect();
    const offset = (containerBox.width / 2) - (item.offsetLeft + box.width / 2);

    this.currentCenter = index;
    this.setState({
      listOffset: offset + 'px'
    });
  }

  render() {
    const { touchEnabled, allOptionsFit, listOffset } = this.state;
    const { chooseTemplate, selectedTemplate, templates } = this.props;

    return (
      <Container>
        <Intro />
        <RelationshipContainer ref={ this.containerRef } allOptionsFit={ allOptionsFit }>
          <Prev shouldDisplay={ !touchEnabled && !allOptionsFit } onClick={() => this.move(-1)} />
          <RelationshipControl touchenabled={ touchEnabled }>
            <RelationshipList ref={ this.listRef } left={ listOffset } allOptionsFit={ allOptionsFit } touchEnabled={ touchEnabled }>
              {
                templates.map((t, i) => {
                  return (
                    <RelationshipOption
                      key={i}
                      as='li'
                      selected={t === selectedTemplate}
                      onClick={() => chooseTemplate(t)}
                    >
                      {t.relationship}
                   </RelationshipOption>
                  );
                })
              }
            </RelationshipList>
          </RelationshipControl>
          <Next shouldDisplay={ !touchEnabled && !allOptionsFit } onClick={() => this.move(1)} />
        </RelationshipContainer>
      </Container>
    )
  }
}


MessageSelector.propTypes = {
  chooseTemplate: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    relationship: PropTypes.string.isRequired
  }),
  templates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    relationship: PropTypes.string.isRequired
  })).isRequired
}

export default MessageSelector;
