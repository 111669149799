import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Intro from './introduction';
import ContactForm from './contact-form';
import Container from './container';
import Card from './card';
import Content from './content';
import PageTitle from '../../components/type/page-title';

const Title = styled(PageTitle)`
  margin: 0 0 40px;
  text-align: center;
`;

const ContactInformation = ({
  cardColor,
  contactValid,
  includeInContest,
  loading,
  onSubmit,
  recipient,
  sender,
  template,
  updateIncludeInContest,
  updateRecipient,
  updateSender
}) => (
  <Container>
    <Content>
      <Title>
        Send it to someone special.
      </Title>
    </Content>
    <Card cardColor={ cardColor }>
      <ContactForm
        contactValid={ contactValid }
        includeInContest={ includeInContest }
        loading={ loading }
        onSubmit={ onSubmit }
        recipient={ recipient }
        sender={ sender }
        template={ template }
        updateIncludeInContest={ updateIncludeInContest }
        updateRecipient={ updateRecipient }
        updateSender={ updateSender }
      />
    </Card>
  </Container>
);

ContactInformation.propTypes = {
  cardColor: PropTypes.string.isRequired,
  contactValid: PropTypes.bool.isRequired,
  includeInContest: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  recipient: PropTypes.string,
  sender: PropTypes.string,
  template: PropTypes.shape({
    senderRequired: PropTypes.bool.isRequired
  }).isRequired,
  updateIncludeInContest: PropTypes.func.isRequired,
  updateRecipient: PropTypes.func.isRequired,
  updateSender: PropTypes.func.isRequired
}

export default ContactInformation;
