import React from 'react';
import styled from 'styled-components';

import Arrow from '../../images/inline-svg/back-arrow.svg';

const Container = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const BackButton = styled.span`
  cursor: pointer;
  padding: 0 20px 0 15px;
  position: relative;
  text-transform: lowercase;
`;

const BackArrow = styled(Arrow)`
  left: 0;
  margin: -10px 0 0;
  position: absolute;
  top: 50%;
  width: 13px;
`;

export default ({ currentPage, goBack, totalPages }) => (
  <Container>
    {
      currentPage !== 1 &&
      <BackButton onClick={ goBack }>
        <BackArrow />
        Back { currentPage === 1 }
      </BackButton>
    }
    <span>
      { currentPage }/{ totalPages }
    </span>
  </Container>
);
