import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Body from '../../components/type/body';
import Card from './card';
import Container from './container';
import Content from './content';
import PageTitle from '../../components/type/page-title';
import Submit from './submit-button';

const Title = styled(PageTitle)`
  margin: 0 0 40px;
  padding: 0 20px;
  text-align: center;
`;

const Message = styled(Body)`
  text-align: center;
  margin: 0 auto 64px;
  max-width: 400px;
`;

const entryText = (includeInContest) => {
  if (includeInContest) {
    return ' and your entry will be entered into the contest.';
  }

  return '.';
}

const Confirmation = ({
  cardColor,
  content,
  includeInContest,
  loading,
  name,
  recipient,
  reset,
  template: Template
}) => (
  <Container>
    <Content viewOnly={ true }>
      <Title>
        Thanks for Sharing
      </Title>
      <Message>
        Your message has been sent to { recipient }{ entryText(includeInContest) }
      </Message>
      <Submit viewOnly={ false } onClick={ reset }>
        Send Another
      </Submit>
    </Content>
    <Card cardColor={ cardColor } viewOnly={ true }>
      <Template
        content={ content }
        loading={ loading }
        messageValid={ true }
        name={ name }
        onSubmit={ () => {} }
        updateContent={ () => {} }
        updateName={ () => {} }
        viewOnly={ true }
      />
    </Card>
  </Container>
);

Confirmation.propTypes = {
  cardColor: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  includeInContest: PropTypes.bool.isRequired,
  name: PropTypes.string,
  recipient: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  template: PropTypes.shape({
    id: PropTypes.number.isRequired,
    relationship: PropTypes.string.isRequired
  })
};

export default Confirmation;
