import React from 'react';
import styled from 'styled-components';

import PageTitle from '../../components/type/page-title';
import Body from '../../components/type/body';

const Container = styled.div`
  padding: 0 24px;
`;

const Title = styled(PageTitle)`
  margin: 0 0 40px;
  padding: 0 20px;
  text-align: center;
`;

const Intro = styled(Body)`
  text-align: center;
  margin: 0 auto;
  max-width: 400px;
`;

const LovePage = () => (
  <Container>
    <Title>
      A maudern love note.
    </Title>
    <Intro>
      Send a message to someone special and opt in for a chance to win a $1,000
      maude gift card. The winner will be selected on&nbsp;
      <a href="https://instagram.com/getmaude" target="_blank">@getmaude</a>’s instagram.&nbsp;
      <a href="https://www.instagram.com/explore/tags/maudernlove/" target="_blank">#maudernlove</a>
    </Intro>
  </Container>
)

export default LovePage;
