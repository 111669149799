import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Confirmation from '../components/love/confirmation';
import ContactInformation from '../components/love/contact-information';
import Nav from '../components/love/navigation';
import SelectColor from '../components/love/select-color';
import SelectMessage from '../components/love/select-message';
import { send } from '../components/love/message';
import templates from '../components/love/templates';
import colors from '../components/love/colors';

const steps = [
  'selectMessage',
  'selectColor',
  'contactInfo',
  'confirmation'
];

const initialState = {
  color: colors[0],
  colors: colors,
  currentStep: steps[0],
  currentPage: 1,
  contactValid: false,
  content: [],
  includeInContest: true,
  loading: false,
  messageValid: false,
  name: '',
  recipient: '',
  sender: '',
  template: templates[0],
  templates: templates,
  totalPages: steps.filter(step => step !== 'confirmation').length
};

class LovePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.confirmation = this.confirmation.bind(this);
    this.contactInfo = this.contactInfo.bind(this);
    this.chooseColor = this.chooseColor.bind(this);
    this.chooseTemplate = this.chooseTemplate.bind(this);
    this.generateContent = this.generateContent.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.reset = this.reset.bind(this);
    this.selectColor = this.selectColor.bind(this);
    this.selectMessage = this.selectMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.submit = this.submit.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.updateIncludeInContest = this.updateIncludeInContest.bind(this);
    this.updateName = this.updateName.bind(this);
    this.updateRecipient = this.updateRecipient.bind(this);
    this.updateSender = this.updateSender.bind(this);
    this.validateContact = this.validateContact.bind(this);
    this.validateMessage = this.validateMessage.bind(this);
  }

  updateRecipient(value) {
    const { template, sender} = this.state;

    this.setState({
      recipient: value,
      sender: template.senderRequired ? sender : value
    }, this.validateContact);
  }

  updateSender(value) {
    this.setState({
      sender: value
    }, this.validateContact);
  }

  updateIncludeInContest(value) {
    this.setState({
      includeInContest: value
    });
  }

  updateContent(index, value = '') {
    const { content } = this.state;

    content[index] = value.slice(0, 48);
    this.setState({ content }, this.validateMessage);
  }

  updateName(value) {
    this.setState({ name: value }, this.validateMessage);
  }

  next() {
    const { currentStep } = this.state;
    const index = steps.indexOf(currentStep);
    const next = index + 1;

    if (steps[next]) this.setState({
      currentPage: next + 1,
      currentStep: steps[next]
    });
  }

  prev() {
    const { currentStep } = this.state;
    const index = steps.indexOf(currentStep);
    const prev = index - 1;

    if (steps[prev]) this.setState({
      currentPage: prev + 1,
      currentStep: steps[prev]
    });
  }

  validateContact() {
    const { recipient, sender } = this.state;
    const test = /@.*\..+/;
    const valid = !!(
          recipient
       && recipient.match(test)
       && sender
       && sender.match(test)
    );

    this.setState({
      contactValid: valid
    });
  }

  validateMessage() {
    const { name, content, template} = this.state;
    const { nameRequired, validateContent } = template;
    const valid = (!nameRequired || (nameRequired && name.trim().length > 0))
               && validateContent(content);

    this.setState({
      messageValid: valid
    });
  }

  chooseTemplate(template) {
    this.setState({
      content: [],
      template
    });
  }

  chooseColor(color) {
    this.setState({
      color
    });
  }

  generateContent() {
    const content = this.state.template.generateContent();

    this.setState({ content }, this.validateMessage);
  }

  contactInfo() {
    const {
      color,
      contactValid,
      includeInContest,
      loading,
      recipient,
      sender,
      template
    } = this.state;

    return (
      <ContactInformation
        cardColor={ color.value }
        contactValid={ contactValid }
        includeInContest={ includeInContest }
        loading={ loading }
        onSubmit={ this.submit }
        recipient={ recipient }
        sender={ sender }
        template={ template }
        updateIncludeInContest={ this.updateIncludeInContest }
        updateRecipient={ this.updateRecipient }
        updateSender={ this.updateSender }
      />
    );
  }

  selectColor() {
    const { color, colors } = this.state;

    return (
      <SelectColor
        chooseColor={ this.chooseColor }
        colors={ colors }
        onSubmit={ this.next }
        selectedColor={ color }
      />
    );
  }

  selectMessage() {
    const {
      color,
      content,
      messageValid,
      name,
      template,
      templates
    } = this.state;

    return (
      <SelectMessage
        cardColor={ color.value }
        chooseTemplate={ this.chooseTemplate }
        content={ content }
        generateContent={ this.generateContent }
        messageValid={ messageValid }
        name={ name }
        onSubmit={ this.next }
        selectedTemplate={ template }
        templates={ templates }
        updateContent={ this.updateContent }
        updateName={ this.updateName }
      />
    );
  }

  confirmation() {
    const {
      color,
      content,
      includeInContest,
      name,
      recipient,
      template
    } = this.state;

    return (
      <Confirmation
        cardColor={ color.value }
        content={ content }
        includeInContest={ includeInContest }
        loading={ false }
        name={ name }
        recipient={ recipient }
        reset={ this.reset }
        template={ template }
      />
    );
  }

  reset() {
    this.setState(initialState);
  }

  submit() {
    this.setState({ loading: true }, this.sendMessage);
  }

  async sendMessage() {
    const {
      color,
      content,
      includeInContest,
      name,
      recipient,
      sender,
      template
    } = this.state;

    try {
      await send(recipient, sender, name, color.id, template.id, content, includeInContest);
      this.setState({
        loading: false,
        currentStep: steps[3]
      });
    }
    catch(e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  render() {
    const { currentStep, currentPage, totalPages } = this.state;
    const nav = currentStep !== 'confirmation' ?
                <Nav
                  currentPage={ currentPage }
                  goBack={ this.prev }
                  totalPages={ totalPages }
                /> :
                undefined;

    return (
      <Layout themeName='dark' flex={true} nav={ nav }>
        <SEO title="Maude | Love" />
        { this[currentStep]() }
      </Layout>
    );
  }
}

export default LovePage;
