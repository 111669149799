import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '../../components/controls/button';

const Container = styled.div`
  @media screen and (min-width: 700px) {
    & {
      display: ${({ single }) => single ? 'block' : 'grid'};
      grid-template-areas:
        "sender recipient"
        "action action";
    }
  }
`;

const Sender = styled.div`
  grid-area: sender;
  padding: 45px 32px 0;

  @media screen and (min-width: 700px) {
    & {
      padding: 8vh 24px;
    }
  }
`;

const Recipient = styled(Sender)`
  grid-area: recipient;
`;

const Action = styled.div`
  grid-area: action;
  padding: 0 32px 48px;
`;

const Label = styled.label`
  color: hsl(0, 0%, 100%);
  display: block;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  text-transform: lowercase;
`;

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid hsl(0, 0%, 100%);
  color: hsl(0, 0%, 100%);
  display: block;
  font-size: 16px;
  line-height: 32px;
  margin: 0 auto;
  max-width: 256px;
  outline: 0;
  text-align: center;
  text-rendering: geometricPrecision;
  width: 100%;

  &::placeholder {
    color: hsla(0, 0%, 100%, 0.5);
    text-rendering: geometricPrecision;
  }
`;

const EntryConfirmation = styled.label`
  box-sizing: border-box;
  color: hsl(0, 0%, 100%);
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin: 63px auto 0;
  max-width: 250px;
  padding: 0 0 0 32px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 700px) {
    & {
      margin: 0 auto;
    }
  }
`;

const Checkbox = styled.input`
  appearance: none;
  background: transparent;
  border: 1px solid hsl(0, 0%, 100%);
  border-radius: 50%;
  cursor: pointer;
  height: 21px;
  left: 0;
  outline: 0;
  position: absolute;
  top: 0;
  width: 21px;

  &:before {
    background: hsla(0, 0%, 100%, 0);
    content: '';
    display: block;
    height: 6px;
    left: 8px;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    transform-origin: 100% 100%;
    transition: all 200ms;
    width: 1px;
  }

  &:checked:before {
    background: hsla(0, 0%, 100%, 1);
  }

  &:after {
    background: hsla(0, 0%, 100%, 0);
    content: '';
    display: block;
    height: 9px;
    left: 8px;
    position: absolute;
    top: 4px;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
    transition: all 200ms;
    width: 1px;
  }

  &:checked:after {
    background: hsla(0, 0%, 100%, 1);
  }
`;

const Submit = styled(Button)`
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  display: block;
  margin: 32px auto 0;
  max-width: 250px;
  opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
  transition: opacity 200ms ease-out;
  width: 100%;
`;

const ContactForm = ({
  contactValid,
  includeInContest,
  loading,
  onSubmit,
  recipient,
  sender,
  template,
  updateIncludeInContest,
  updateRecipient,
  updateSender
}) => (
  <Container single={ !template.senderRequired }>
    {
      template.senderRequired &&
      <Sender>
        <Label>
          From
        </Label>
        <Input
          placeholder='your email'
          value={ sender }
          onChange={e => updateSender(e.target.value) }
        />
      </Sender>
    }
    <Recipient>
      <Label>
        To
      </Label>
      <Input
        placeholder="the recipient's email"
        value={ recipient }
        onChange={e => updateRecipient(e.target.value) }
      />
    </Recipient>
    <Action>
      <EntryConfirmation htmlFor="confirm_entry">
        <Checkbox
          checked={ includeInContest }
          id="confirm_entry"
          onChange={ e => updateIncludeInContest(e.target.checked) }
          type="checkbox"
        />
        Please include my message in the contest to win $1000 worth of Maude
        product. Must be following maude’s instagram
        (<a href="https://www.instagram.com/getmaude/" target="_blank">@getmaude</a>)
        and be 18 in the US.&nbsp;
        <a href="https://getmaude.com/pages/maudern-love-note-terms-of-service" target="_blank">
          Terms
        </a>
      </EntryConfirmation>
      <Submit
        disabled={ !contactValid || loading }
        onClick={ onSubmit }
      >
        { loading ? "Sending..." : "Send It" }
      </Submit>
    </Action>
  </Container>
);

ContactForm.propTypes = {
  contactValid: PropTypes.bool.isRequired,
  includeInContest: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  recipient: PropTypes.string,
  sender: PropTypes.string,
  template: PropTypes.shape({
    senderRequired: PropTypes.bool.isRequired
  }).isRequired,
  updateIncludeInContest: PropTypes.func.isRequired,
  updateRecipient: PropTypes.func.isRequired,
  updateSender: PropTypes.func.isRequired
}

export default ContactForm;
